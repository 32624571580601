<template>
  <div class="home scroll-list">
    <v-card>
      <v-container>
        <v-list nav>
          <v-list-item link :to="{ name: 'home' }">
            <v-list-item-action>
              <v-icon id="home-icon">mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'licenses' }">
            <v-list-item-action>
              <v-icon id="license-icon">mdi-table-of-contents</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Lisenser</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'createLicense' }">
            <v-list-item-action>
              <v-icon id="license-icon">mdi-ticket-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Opprett lisens</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'videoer' }">
            <v-list-item-action>
              <v-icon id="video-icon">mdi-table-of-contents</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Videoer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'createVideo' }">
            <v-list-item-action>
              <v-icon id="video-icon">mdi-video-plus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Opprett video</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'categories' }">
            <v-list-item-action>
              <v-icon id="category-icon">mdi-shape</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Kategorier</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout" v-if="loggedIn">
            <v-list-item-action>
              <v-icon id="logout-icon">mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logg ut</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="goTo('auth')" v-else>
            <v-list-item-action>
              <v-icon id="login-icon">mdi-login</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logg inn</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home",
  // Vuetify components provide
  // a theme variable that is
  // used to determine dark
  computed: {
    loggedIn() {
      return this.$store.state.user;
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
    async logout() {
      console.log("logging out");
      // await Auth.signOut()
      this.$store.dispatch("setUser", null);
    },
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
